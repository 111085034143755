import React, { useState, useRef, useEffect } from "react";
import axios from "axios"; // Импортируем axios
import LoadingPage from './LoadingPage';
import { useLanguage } from './LanguageContext';
import AuthorisationError from './AuthorisationError';
import Cookies from 'js-cookie';

function Coach() {
  const { language } = useLanguage();
  const strings = require(`./${language}.json`);

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(null);
  const [userCreatorId, setUserCreatorId] = useState(null); // Состояние для user_creator_id
  const [userCreatorFirstName, setUserCreatorFirstName] = useState(null); // Состояние для user_creator_id
  const [prompt, setPrompt] = useState("");
  const [is404Error, setIs404Error] = useState(false);
  // const csrfToken = Cookies.get("csrftoken");
  const [messages, setMessages] = useState([
    {
      prompt: "",
      response: `Hi, I'm Empatika - your personal AI-coach. Thank you for taking the test. Take a look at what I learned about you: `,
      isStreaming: false
    },
    {
      prompt: "",
      response:
`
Now that I understand your values and personality traits, see what we can do together:

1. Personal development
Realizing your values and life priorities.
Developing self-confidence and self-esteem.
Managing emotions and stress tolerance.
Finding work-life balance.
Forming healthy habits.
Developing self-discipline.
Developing a strategy to influence society.
Realizing one's role and contribution to the community
Unlocking creativity and creativity.
Finding meaning and inspiration.
Creating a unique personal brand.

2. Personal crises and change
Overcoming fear of change.
Overcoming life crises (personal, age, professional).
Working with loss of meaning or direction.
Preparing for major life events (wedding, public speaking, moving).
Adapting to significant changes (moving, divorce, job change).
Adapting to a new culture or environment.
Recovering from traumatic experiences (work within the coachee's area of expertise).
Working with burnout.
Developing the skill of adapting to change.

3. Goal setting and motivation
Setting clear, achievable goals (personal, professional, financial).
Defining a long-term vision (life vision).
Forming a strategy to achieve goals.
Overcoming procrastination.
Increasing motivation and energy.
Creating a system of self-control.

4. Relationships
Improving personal or business relationships.
Resolution of conflicts.
Creating a harmonious atmosphere in the family or workplace.
Forming healthy boundaries.
Finding and strengthening connections with people who share your values.

5. Career and professional development
Identification of the actual professional sphere.
Transition to a new career stage.
Preparing for an important negotiation or interview.
Developing leadership skills.
Finding professional purpose.
Developing a career change strategy.
Creating a professional development plan.
Improving business communication and negotiation skills.
Managing a team and improving performance.

6. Finance and financial well-being
Setting financial goals.
Optimizing expenses and managing personal finances.
Finding ways to increase income.
Formation of financial thinking

7. Entrepreneurship and business
Creating a long-term project that aligns with intrinsic values.
Developing a business idea or strategy for launching a startup.
Creating a long-term business strategy.
Setting and realizing ambitious business goals.
Developing the skills of an entrepreneur.
Team building and role allocation.
Working on personal effectiveness in business.
Getting out of operational management.

8. Efficiency and leadership
Improving time management skills.
Forming a system of priorities.
Managing large volumes of tasks.
Developing focus and concentration.
Optimization of work processes.
Developing leadership skills.
Creating a motivational environment for the team.
Improving management skills.
Making complex management decisions.
Increasing authority in the team.

Where do you want to start?
`,
isStreaming: false
    },
  ]); // Предопределённые сообщения
  const [currentResponse, setCurrentResponse] = useState(""); // eslint-disable-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [authorisationError, setAuthorisationError] = useState(false);

  const chatBoxRef = useRef(null); // Реф для контейнера сообщений

  // Прокручиваем контейнер к последнему сообщению
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const token = Cookies.get('authToken'); // Получаем токен из cookies
      try {
        const response = await axios.get(
          window.location.origin === 'http://localhost:3000'
            ? 'http://localhost:8000/api/subscription_status/'
            : `${window.location.origin}/api/subscription_status/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setIsSubscriptionActive(response.data.subscription_is_active); // Устанавливаем статус подписки
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          setAuthorisationError(true);
          Cookies.remove('authToken');
        }
        console.error('Subscription error:', error);
        setIsSubscriptionActive(false); // Если произошла ошибка, считаем, что подписка неактивна
      }
    };

    fetchSubscriptionStatus();
  }, []); // Пустой массив зависимостей, чтобы функция вызывалась только при монтировании компонента
  

  const handleRequestError = (error) => {
      setIsLoading(false);
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          setAuthorisationError(true);
          Cookies.remove('authToken');
        } else if (error.response.status === 404) {
          setIs404Error(true);
        } else {
          // setError('An unexpected error occurred. Please try again later.');
          console.error('API error:', error.response.data.detail);
        }
      } else {
        console.error('Error:', error.message);
      }
    };

  const handleSubscription = async () => {
    const token = Cookies.get('authToken');
    try {
      const response = await axios.post(
        window.location.origin === 'http://localhost:3000'
          ? 'http://localhost:8000/api/create_subscription/'
          : `${window.location.origin}/api/create_subscription/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );
      window.location.href = response.data.subscription_url;
    } catch (error) {
      handleRequestError(error);
    }
  };

  // Новый useEffect для получения user_creator_id
  useEffect(() => {
    const fetchUserCreatorData = async () => {
      const token = Cookies.get('authToken'); // Получаем токен из cookies
      try {
        const response = await axios.get(
          window.location.origin === 'http://localhost:3000'
            ? 'http://localhost:8000/api/user_creator_data/'
            : `${window.location.origin}/api/user_creator_data/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setUserCreatorId(response.data.user_creator_id); // Сохраняем user_creator_id в состоянии
        setUserCreatorFirstName(response.data.user_creator_first_name); // Сохраняем user_creator_id в состоянии
        setIs404Error(false);
        setIsLoading(true);
      } catch (error) {
        console.error('Error fetching user creator data:', error);
        if (error.response && error.response.status === 404) {
          setIs404Error(true);
        }
        setUserCreatorId(null); // Если произошла ошибка, сбрасываем состояние
        setUserCreatorFirstName(null); // Если произошла ошибка, сбрасываем состояние
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserCreatorData();
  }, []); // Пустой массив зависимостей, чтобы функция вызывалась только при монтировании компонента

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) return; // Игнорируем пустые запросы
  
    setIsLoading(true);
    setCurrentResponse("");
    setErrorMessage(""); // Сбрасываем предыдущее сообщение об ошибке
    const token = Cookies.get('authToken');

    setMessages((prevMessages) => [
      ...prevMessages,
      { prompt, response: "", isStreaming: true }, // Устанавливаем флаг стриминга
    ]);
  
    try {
      const res = await fetch(
        window.location.origin === "http://localhost:3000"
          ? "http://localhost:8000/api/gpt/"
          : `${window.location.origin}/api/gpt/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt }),
          credentials: "include", // Включаем cookies
        }
      );
  
      if (!res.ok) {
        const errorData = await res.json(); // Попробуем получить JSON с ошибкой
        const errorMessage = errorData.error || `Error response:: ${res.statusText}`;
        throw new Error(errorMessage); // Передаём сообщение об ошибке
      }
  
      const reader = res.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let accumulatedText = "";
  
      const updateMessage = (text) => {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastIndex = updatedMessages.length - 1;
          if (lastIndex >= 0) {
            updatedMessages[lastIndex] = {
              ...updatedMessages[lastIndex],
              response: text,
              isStreaming: true // Сохраняем флаг во время обновлений
            };
          }
          return updatedMessages;
        });
      };
  
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   { prompt, response: "", isStreaming: true }, // Устанавливаем флаг стриминга
      // ]);
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n");
  
        for (const line of lines) {
          const cleanLine = line.replace(/^data: /, "").trim();
          if (cleanLine) {
            accumulatedText += cleanLine + " ";
            updateMessage(accumulatedText.trim());
          }
        }
      }
  
      updateMessage(accumulatedText.trim());
      setErrorMessage(""); // Сбрасываем сообщение об ошибке, если запрос успешен
      setMessages(prev => prev.map((msg, i) => 
        i === prev.length - 1 ? {...msg, isStreaming: false} : msg
      ));
    } catch (err) {
      console.error("Error:", err);
      setErrorMessage(err.message); // Устанавливаем сообщение об ошибке
      setMessages(prev => prev.map((msg, i) => 
        i === prev.length - 1 ? {...msg, isStreaming: false} : msg
      ));
    } finally {
      setIsLoading(false);
      setPrompt("");
    }
  };

  // Отправка при нажатии клавиши Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  // Условный рендеринг
  if (isSubscriptionActive === null) {
    return <LoadingPage />;
  }

  // if (!isSubscriptionActive) {
  //   return <p>{strings.noActiveSubs}</p>;
  // }

  if (is404Error) {
    return <h3 style={styles.noMessages}>No messages</h3>;
  }

  if (authorisationError) {
    Cookies.remove('authToken');
    return <AuthorisationError />;
  }

  // Формируем ссылку на отчет
  const reportLink = userCreatorId
    ? window.location.origin === 'http://localhost:3000'
      ? `http://localhost:3000/report-result/${userCreatorId}`
      : `${window.location.origin}/report-result/${userCreatorId}`
    : null;

  return (
    <div style={styles.pageContainer}>
      <div style={styles.chatContainer}>
        <h1 style={styles.header}>Your personal chat with Empatika</h1>
        <div ref={chatBoxRef} style={styles.chatBox}>
          {" "}
          {/* Привязка ref */}
          <ul style={styles.messageList}>
          {messages.map((msg, index) => (
            <li key={index} style={styles.messageItem}>
              {msg.prompt && (
                <div style={styles.clientMessage}>
                  <div style={styles.messageSenderClient}>{userCreatorFirstName}</div>
                  <div style={styles.messageTextClient}>{msg.prompt}</div>
                </div>
              )}
              {msg.response && (
                <div 
                style={{
                  ...styles.empatikaMessage,
                  ...(msg.isStreaming && styles.streamingMessage)
                }}
                >
                  <div style={styles.messageSenderEmpatika}>Empatika</div>
                  <div style={styles.messageTextEmpatika}>
                    {msg.response}
                    {msg.isStreaming && (
                      <span style={styles.streamingCursor}>▋</span>
                    )}
                    {index === 0 && reportLink && (
                      <a href={reportLink} target="_blank" rel="noopener noreferrer">
                        [link to report]
                      </a>
                    )}
                  </div>
                </div>
              )}
            </li>
          ))}
          </ul>
          {isLoading && <p style={styles.loadingText}>Loading...</p>}
        </div>
        <form onSubmit={handleSubmit} style={styles.form}>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={handleKeyDown} // Обработка Enter
            placeholder="Enter your query..."
            rows="4"
            style={styles.textarea}
          />
          {errorMessage && (
            <div style={styles.errorMessage}>
              <p>{errorMessage}</p>
            </div>
          )}
          {isSubscriptionActive ? (
            <button type="submit" style={styles.button}>
              Send
            </button> 
          ) : (
            <button
              className="ReturnToPersonalAccount"
              style={styles.button}
              onClick={handleSubscription}
            >
              {strings.subscribe}
            </button> 
          )}
        </form>
      </div>
    </div>
  );
}

const styles = {
  // Светлый фон страницы
  pageContainer: {
    backgroundColor: "#f5f5f5",
    display: "flex", 
    justifyContent: "center",
    alignItems: "center", // Меняем на center для вертикального центрирования
    fontFamily: "'Roboto', sans-serif",
    color: "#333",
    padding: "20px 10px",
    minHeight: "100vh",
    width: "100%",
  },
  chatContainer: {
    width: "100%", // Устанавливаем фиксированный процент ширины
    maxWidth: "900px", // Ограничиваем максимальную ширину
    height: "85vh",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    margin: "auto", // Центрируем контейнер
    marginTop: "-70px",
  },
  header: {
    textAlign: "center",
    color: "#333",
    fontSize: "20px",
    marginBottom: "15px",
    flexShrink: 0, // Предотвращает сжатие заголовка
  },
  // Оформление блока с историей сообщений
  chatBox: {
    flex: 1,
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    padding: "15px",
    overflowY: "auto",
    scrollBehavior: "smooth",
    border: "1px solid #ddd",
    marginBottom: "15px", // Небольшой отступ перед формой
  },
  historyTitle: {
    fontSize: "18px",
    marginBottom: "10px",
    color: "#555",
  },
  noMessages: {
    fontWeight: "bold",
    color: "#888",
  },
  messageList: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    gap: "15px", // Отступы между сообщениями
  },
  messageItem: {
    marginBottom: "15px",
    width: "100%",
  },
  // Стили для сообщений клиента
  clientMessage: {
    backgroundColor: "#ADD4FF",
    padding: "10px",
    borderRadius: "12px 12px 12px 0",
    marginLeft: "auto",
    marginRight: "0",
    maxWidth: "90%", // Увеличено с 80%
    wordBreak: "break-word", // Добавлено для переноса длинных слов
    marginBottom: "25px"
  },
  
  // Стили для сообщений Empatika
  empatikaMessage: {
    backgroundColor: "#f1f1f1",
    padding: "10px",
    borderRadius: "12px 12px 0 12px",
    marginRight: "auto",
    marginLeft: "0",
    maxWidth: "90%", // Увеличено с 80%
    wordBreak: "break-word", // Добавлено для переноса длинных слов
  },
  
  messageSenderClient: {
    fontWeight: "bold",
    color: "#fa5400",
    marginBottom: "5px",
  },
  
  messageSenderEmpatika: {
    fontWeight: "bold",
    color: "#fa5400",
    marginBottom: "5px",
  },
  // messageSender: {
  //   fontWeight: "bold",
  //   color: "#fa5400", // Оранжевый цвет для имени отправителя
  // },
  messageTextClient: {
    marginTop: "5px",
    color: "#333",
  },
  messageTextEmpatika: {
    marginTop: "5px",
    color: "#333",
    whiteSpace: "pre-wrap",
  },
  loadingText: {
    color: "#888",
    textAlign: "center",
    fontSize: "16px",
  },
  form: {
    flexShrink: 0, // Предотвращает сжатие формы
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
  },
  textarea: {
    width: "100%",
    padding: "10px",
    resize: "none",
    borderRadius: "8px",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
    color: "#333",
    fontSize: "16px",
    marginBottom: "10px",
    maxHeight: "100px", // Ограничение высоты textarea
    boxSizing: "border-box",
  },
  button: {
    padding: "12px 20px",
    backgroundColor: "#fa5400",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background-color 0.3s ease",
  },
  buttonHover: {
    backgroundColor: "#0056b3", // Темнее при наведении
  },
  errorMessage: {
    color: "red",
    backgroundColor: "#ffe6e6",
    padding: "10px",
    borderRadius: "8px",
    marginBottom: "15px",
    textAlign: "center",
    fontSize: "14px",
  },
  streamingMessage: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.5), transparent)',
      animation: 'shimmer 1.5s infinite',
    }
  },
  streamingCursor: {
    display: 'inline-block',
    marginLeft: 4,
    animation: 'blink 1s infinite',
    color: '#fa5400',
    fontWeight: 'bold',
  },
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 },
  },

  '@keyframes shimmer': {
    '0%': { transform: 'translateX(-100%)' },
    '100%': { transform: 'translateX(100%)' },
  },
  // Медиа-запрос для мобильных устройств
  '@media (max-width: 768px)': {
    chatContainer: {
      width: "95%",
      height: "90vh", // Используем vh для мобильных устройств
      padding: "10px",
      margin: "10px auto", // Добавляем отступы сверху и снизу
    },
    
    header: {
      fontSize: "18px",
    },
    
    textarea: {
      fontSize: "14px",
    },
    
    button: {
      padding: "8px 15px",
      fontSize: "14px",
    }
  }
};

export default Coach;