import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLanguage } from './LanguageContext';



const RegistrationPage = () => {
  const { language } = useLanguage();
  const strings = require(`./${language}.json`);
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
  });
  const [formErrors, setFormErrors] = useState({
    email: false,
    password: false,
    first_name: false,
    last_name: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Проверяем корректность адреса электронной почты при вводе
    if (name === 'email') {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    // Проверяем все поля на заполнение
    const newFormErrors = {};
    Object.entries(formData).forEach(([name, value]) => {
      newFormErrors[name] = value.trim() === '';
    });

    // Проверяем корректность адреса электронной почты
    newFormErrors.email = !validateEmail(formData.email);

    setFormErrors(newFormErrors);

    // Если нет ошибок валидации, отправляем запрос на сервер
    if (Object.values(newFormErrors).every((error) => !error)) {
      try {
        const response = await axios.post(
          window.location.origin === 'http://localhost:3000'
            ? 'http://localhost:8000/api/auth/users/'
            : `${window.location.origin}/api/auth/users/`,
          formData
        );
        if (response.status === 201) {
          setRegistrationSuccess(true); // Устанавливаем флаг успешной регистрации
          // Сбрасываем поля формы
          setFormData({
            email: '',
            password: '',
            first_name: '',
            last_name: '',
          });
        }
      } catch (error) {
        if (error.response) {
          // Ошибка от API
          if (error.response.status === 400) {
            setApiErrors(error.response.data.email || error.response.data.password); // Отображаем данные ошибки
          } else {
            setApiErrors(['Registration failed. Please try again later.']); // Общая ошибка API
          }
        } else {
          // Другие ошибки
          console.error('Registration failed:', error.message);
        }
      }
    }
  };

  const validateEmail = (email) => {
    // Простая проверка на корректность адреса электронной почты
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleAuthorization = () => {
    // Перенаправляем пользователя на главную страницу
    window.location.href = '/';
  };

  return (
    <div>
      <h2 className='registration'>{strings.registration}</h2>
      <form className="registration-form" onSubmit={handleSubmit}>
        {registrationSuccess ? (
          <div>
            <p style={{ color: 'green' }}>{strings.registrationSuccess}</p>
            <button onClick={handleAuthorization}>{strings.goToAuthorization}</button>
          </div>
        ) : (
          <>
            <input
              type="email"
              name="email"
              placeholder={strings.email}
              value={formData.email}
              onChange={handleChange}
              required
              lang="en"
            />
            {isSubmitted && formErrors.email && <span style={{ color: 'red' }} lang="en">Email is invalid</span>}
            <br />
            <input
              type="password"
              name="password"
              placeholder={strings.password}
              value={formData.password}
              onChange={handleChange}
              required
            />
            <br />
            <input
              type="text"
              name="first_name"
              placeholder={strings.firstName}
              value={formData.first_name}
              onChange={handleChange}
              required
            />
            <br />
            <input
              type="text"
              name="last_name"
              placeholder={strings.lastName}
              value={formData.last_name}
              onChange={handleChange}
              required
            />
            <br />
            {isSubmitted && Object.entries(formErrors).map(([field, error]) => (
              error && <span key={field} style={{ color: 'red' }}>{field} is required</span>
            ))}
            {apiErrors.length > 0 && (
              <div>
                {apiErrors.map((error, index) => (
                  <p key={index} style={{ color: 'red' }}>{error}</p>
                ))}
              </div>
            )}
            <br />
            <button type="submit">{strings.register}</button>
          </>
        )}
      </form>
    </div>
  );
};

export default RegistrationPage;